import styled, { css } from 'styled-components';
import COLORS from './helpers/colors';

const primary = css`
  color: ${COLORS.primary};
`;

const warning = css`
  color: ${COLORS.red};
`;

const success = css`
  color: ${COLORS.green};
`;

const danger = css`
  color: ${COLORS.red};
`;

const blur = css`
  color: ${COLORS.transparent};
  text-shadow: 0 0 12px ${COLORS.text.l0};
`;

const xsmall = css`
  font-size: 11px;
  line-height: 14px;
`;

const small = css`
  font-size: 12px;
  line-height: 18px;
`;

const medium = css`
  font-size: 14px;
  line-height: 20px;
`;

const large = css`
  font-size: 16px;
  line-height: 24px;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const h1 = css`
  font-size: 48px;
  line-height: 1.2;
`;
const h2 = css`
  font-size: 32px;
  line-height: 1.2;
`;
const h3 = css`
  font-size: 25px;
  line-height: 1.2;
`;
const h4 = css`
  font-size: 22px;
  line-height: 1.2;
`;
const h5 = css`
  font-size: 20px;
  line-height: 1.2;
`;
const h6 = css`
  font-size: 18px;
  line-height: 1.2;
`;

const inherit = css`
  font-size: inherit;
  color: inherit;
`;

const isSelected = css`
  border-bottom: ${props =>
    props.selectedBorder || `solid 1px ${COLORS.text.highlight}`};
  padding: 12px;
  color: ${COLORS.text.l0};
  font-weight: bold;
`;

const ellipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Text = styled.span`
  display: ${props => props.display || 'block'};
  border-bottom: ${props => props.defaultSelectedBorder};
  color: ${props =>
    props.white ? COLORS.white : props.color ? props.color : COLORS.text.l2};
  ${props => (props.primary ? primary : null)};
  ${props => (props.warning ? warning : null)};
  ${props => (props.success ? success : null)};
  ${props => (props.danger ? danger : null)};
  ${props => (props.blur ? blur : null)};
  ${props => props.isSelected && isSelected} ${props =>
    props.xsmall
      ? xsmall
      : props.small
      ? small
      : props.medium
      ? medium
      : props.large
      ? large
      : props.h1
      ? h1
      : props.h2
      ? h2
      : props.h3
      ? h3
      : props.h4
      ? h4
      : props.h5
      ? h5
      : props.h6
      ? h6
      : props.inherit
      ? inherit
      : medium};

  width: ${props => (props.fullWidth ? '100%' : props.width)};
  text-transform: ${props =>
    props.uppercase ? 'uppercase' : props.capitalize ? 'capitalize' : null};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  text-align: ${props =>
    props.right
      ? 'right'
      : props.center
      ? 'center'
      : props.inherit
      ? 'inherit'
      : 'left'};
  font-weight: ${props =>
    props.bold
      ? 'bold'
      : props.semiBold
      ? '600'
      : props.heavy
      ? '500'
      : props.weight
      ? props.weight
      : 400};
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
  letter-spacing: ${props => props.spacing || (props.uppercase && '0.02em')};

  box-sizing: ${props => props.width && 'content-box'};
  max-width: ${props => props.maxWidth};
  font-style: ${props => props.fontStyle};
  word-wrap: ${props => (props.wrap ? 'break-word' : null)};
  white-space: ${props => props.whiteSpace};
  overflow-wrap: ${props => props.overflowWrap};
  overflow: ${props => props.overflow};
  max-height: ${props => props.maxHeight};
  min-height: ${props => props.minHeight};
  word-break: ${props => props.wordBreak};
  background-color: ${props => props.bgColor};
  flex-grow: ${props => props.flexGrow};
  z-index: ${props => props.zIndex};
  text-overflow: ${props => props.textOverflow};
  list-style-position: ${props => (props.bulletInside ? 'inside' : null)};
  opacity: ${props => props.opacity};
  border-radius: ${props => props.borderRadius};
  &:hover {
    color: ${props => props.hoverColor};
    cursor: ${props => props.cursor};
  }
  ${props =>
    props.noWrap && { whiteSpace: 'nowrap', textOverflow: 'ellipsis' }};
  ${props => props.underline && { textDecoration: 'underline' }};
  @media only screen and (max-width: 768px) {
    font-size: ${props => props.mediaSize};
    padding: ${props => props.mediaPadding};
    text-align: ${props => props.mediaAlign};
  }

  ${props => (props.ellipsis ? ellipsis : null)}
`;

export default Text;
