import Big from 'big.js';
import config from './ApiConstants';

export const LIMIT_API_RESPONSE = 10;

export const AMOUNT = 'AMOUNT';
export const CUMULATIVE = 'CUMULATIVE';

export const GRAPH_LIMIT = 100;

export const ORDER_HISTORY_LIMIT = 50;

export const INSTANT_WITHDRAWAL_FEE_PERCENTAGE = 1.18;

export const EXPIRY_TIMEOUT_MINUTES = 60;

export const CANCEL_TIMEOUT_MINUTES = 30;

export const P2P_BLOCKING_TRADE_COUNT = 2;

export const P2P_ORDER_BOOK_RECORD_LIMIT = 10;

export const P2P_TRADE_HISTORY_RECORD_LIMIT = 10;

export const P2P_TRADE_HISTORY_MOBILE_RECORD_LIMIT = 25;

export const P2P_ORDER_BOOK_MOBILE_RECORD_LIMIT = 20;

export const P2P_MY_ORDERS_RECORD_LIMIT = 10;

export const P2P_MY_ORDERS_MOBILE_RECORD_LIMIT = 20;

export const DHAMAKA_DEFAULT_VOLUME = 10;

export const DHAMAKA_DEFAULT_CURRENCY = 'ltc';

export const DEFAULT_CURRENCY_PRECISION = 4;

export const CLEAVE_DATE_RAW_VALUE_FORMAT = 'ddMMyyyy';

export const DEFAULT_POLLING_INTERVAL = 3600000;

export const REDEEMED_COUPONS_LIMIT = 5;

export const UPDATE_TIMEOUT = 60000;

export const THROTTLE_WAIT = 60 * 1000;

export const BIG_ZERO = new Big(0);

export const EXCHANGE_START_DATE = new Date('2018-03-08');

export const GST_INVOICE_START_DATE = new Date('2021-01-01');

export const UPI_COLLECT_EXPIRY_DURATION = 300;

export const MAX_PAYMENT_OPTIONS_LIMIT = 5;

export const CURRENCY = {
  INR: 'inr',
  WRX: 'wrx',
  BTC: 'btc',
  USDT: 'usdt',
  EUR: 'eur',
  USD: 'usd'
};

export const INR_CURRENCY_DEFAULT_PARAMS = {
  renderFormat: '%s%v',
  thousandsSeparator: ',',
  decimalSeparator: '.',
  symbol: '₹'
};

export const CRYPTO_CURRENCY_DEFAULT_PARAMS = {
  renderFormat: '%v %s',
  thousandsSeparator: ',',
  decimalSeparator: '.'
};

export const FIAT_CURRENCY_DEFAULT_PARAMS = {
  renderFormat: '%s%v',
  thousandsSeparator: ',',
  decimalSeparator: '.'
};

export const SIDE = {
  BUY: 'buy',
  SELL: 'sell'
};

export const USER_TYPE = {
  BUYER: 'buyer',
  SELLER: 'seller'
};

export const KIND_TO_SIDE_MAPPER = {
  ask: 'sell',
  bid: 'buy'
};

export const TRADE_STATES = {
  WAIT: 'wait',
  EXPIRED: 'expired',
  CANCEL: 'cancel',
  DONE: 'done',
  DISPUTE: 'dispute',
  DISPUTE_DONE: 'dispute_done',
  DISPUTE_CANCEL: 'dispute_cancel'
};

export const ORDER_STATES = {
  WAIT: 'wait',
  CANCEL: 'cancel',
  DONE: 'done'
};

export const DERIVED_ORDER_STATES = {
  COMPLETE: 'Complete',
  PARTIALLY_COMPLETE: 'Partially Complete',
  CANCELLED: 'Cancelled',
  OPEN: 'Open',
  UNKNOWN: 'Unknown'
};

export const PUSHER_CONNECTION_STATES = {
  INITIALIZED: 'initialized',
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  UNAVAILABLE: 'unavailable',
  FAILED: 'failed',
  DISCONNECTED: 'disconnected'
};

export const P2P_VIEW_STATES = {
  BUYSELL: 'buysell',
  MATCHING: 'matching',
  MATCHED: 'matched'
};

export const ANDROID_APP_DOWNLOAD_LINK_HOME =
  'https://play.google.com/store/apps/details?id=com.wrx.wazirx&referrer=utm_source%3DWazirX%2520Desktop%26utm_medium%3DHome%26utm_term%3DDownload%2520App';

export const ANDROID_APP_DOWNLOAD_LINK_FOOTER =
  'https://play.google.com/store/apps/details?id=com.wrx.wazirx&referrer=utm_source%3DWazirX%2520Desktop%26utm_medium%3DFooter%26utm_term%3DDownload%2520App';

export const ANDROID_APP_DOWNLOAD_LINK_HEADER =
  'https://play.google.com/store/apps/details?id=com.wrx.wazirx&referrer=utm_source%3DWazirX%2520Desktop%26utm_medium%3DHeader%2520Menu%26utm_term%3DDownload%2520App';

export const ANDROID_APP_DOWNLOAD_HERO_IMAGE =
  'https://play.google.com/store/apps/details?id=com.wrx.wazirx&referrer=utm_source%3DWazirX%2520Desktop%26utm_medium%Hero%2520Image%26utm_term%3DDownload%2520App';

export const IOS_APP_DOWNLOAD_LINK =
  'https://itunes.apple.com/in/app/wazirx/id1349082789?mt=8';

export const APP_DOWNLOAD_LINK = `${config.DOMAIN_HOST}/app/download`;

export const WIN_APP_DOWNLOAD_LINK =
  'https://download.wazirx.com/desktop-apps/WazirXSetup-win.exe';

export const MAC_APP_DOWNLOAD_LINK =
  'https://download.wazirx.com/desktop-apps/WazirXSetup-mac.dmg';

export const REFERRAL_PROGRAM_PARTICIPATION =
  'https://support.wazirx.com/hc/en-us/articles/360000766554';

export const WRX_COIN_SUPPORT_LINK =
  'https://support.wazirx.com/hc/en-us/articles/360001664033';

export const WRX_COIN_INDEX_SUPPORT_LINK =
  'https://support.wazirx.com/hc/en-us/sections/360000344233';

export const MAKER_TAKER_SUPPORT_LINK =
  'https://support.wazirx.com/hc/en-us/articles/360001291234';

export const SECURITY_SUPPORT_LINK =
  'https://support.wazirx.com/hc/en-us/articles/360001621054';

export const MINE_WRX_SUPPORT_LINK =
  'https://support.wazirx.com/hc/en-us/articles/360005940494';

export const LIST_YOUR_TOKEN_SUPPORT_LINK =
  'https://goo.gl/forms/vTnNXGh7oV77pe6E2';

export const P2P_PREFERRED_TRADE_SUPPORT_LINK =
  'https://wazirx.zendesk.com/hc/en-us/articles/360007405413';

export const DEPOSIT_DHAMAKA_SUPPORT_LINK =
  'https://support.wazirx.com/hc/en-us/articles/360008532474';

export const WAZIRX_REFERRAL_SUPPORT_LINK =
  'https://support.wazirx.com/hc/en-us/articles/360000766554';

export const XID_OB_SUPPORT_LINK =
  'https://wazirx.zendesk.com/hc/en-us/articles/360012598853';

export const ACQUISITION_ANNOUNCEMENT =
  'https://medium.com/wazirx/the-next-phase-of-wazirx-begins-part-1-aae88578779d';

export const P2P_HOW_IT_WORKS =
  'https://support.wazirx.com/hc/en-us/articles/360007205514';

export const WAZIRX_STOP_LIMIT_INFO =
  'https://support.wazirx.com/hc/en-us/articles/360021320673';

export const WAZIRX_STF_PAYOUTS_LINK =
  'https://support.wazirx.com/hc/en-us/articles/360039122293';

export const WAZIRX_FEES_RAPID_LISTING =
  'https://wazirx.com/blog/rapid-listing-initiative-on-wazirx/';

export const REACT_INTL_TELEPHONE_UTILS_JS =
  'https://s3.ap-south-1.amazonaws.com/p.wazirx.com/utils/wazirx_utils.js';

export const WRX_RELEASE_SCHEDULE =
  'https://support.wazirx.com/hc/en-us/articles/900000184066';

export const TERMS_OF_USE =
  'https://s3.ap-south-1.amazonaws.com/wrx-assets/WazirXUserAgreement.pdf?v1';

export const NFT_MARKET_PLACE = 'https://nft.wazirx.org/';

export const API_DOCUMENTATION =
  'https://docs.wazirx.com/?utm_source=wazirxmainwebsite&utm_medium=referral&utm_campaign=docs-wxweb-link-footer';

export const WAZIRX_TDS_SUPPORT_LINK =
  'https://support.wazirx.com/hc/en-us/articles/4701662097050';

export const WAZIRX_BINANCE_DEACTIVATED_BLOG_LINK =
  'https://support.wazirx.com/hc/en-us/articles/4842250390426';

export const NOMINEE_FAQS_LINK =
  'https://support.wazirx.com/hc/en-us/articles/5209608913562-Nomination-FAQs';

export const P2P_DEMO_VIDEOS = {
  THUMBNAIL:
    'https://media.wazirx.com/web_assets/p2p/instruction/{{type}}/{{platform}}/{{theme}}/{{resolution}}.png',
  BUY_VIDEO_ID: 'D7KzpWJAisE',
  BUY_VIDEO_DURATION: '02:32',
  SELL_VIDEO_ID: 'IHGghd3yj5M',
  SELL_VIDEO_DURATION: '01:38'
};

export const TRANSACTION_STATES = {
  DONE: 'done',
  SUBMITTING: 'submitting',
  SUBMITTED: 'submitted',
  PROCESSING: 'processing',
  ACCEPTED: 'accepted',
  ALMOST_DONE: 'almost_done',
  CANCELLED: 'canceled',
  REJECTED: 'rejected',
  FAILED: 'failed',
  SUSPECT: 'suspect',
  CHECKED: 'checked',
  WARNING: 'warning',
  VERIFICATION_PENDING: 'verification_pending',
  UNKNOWN: 'unknown'
};

export const PLATFORM = {
  WEB: 'web',
  ANDROID: 'android',
  IOS: 'iOS',
  WINDOWS: 'windows',
  MAC: 'mac'
};

export const CONTEST_HEADERS = {
  ordercount: 'Order Count',
  ordervalue: 'Order Value',
  rank: 'Rank'
};

export const EMPTY_VALUES = [null, undefined, '', '.', ',', false];

export const ZERO_VALUES = [null, undefined, 0, '', '0', '0.0', '.'];

export const FORCE_REDEPLOY_CNTR = 5;

export const RECAPTCHA_TYPE = 'invisible';

export const CONTENT_TYPES = {
  NUMERIC: 'numeric',
  TEXT: 'text'
};

export const ORDER_BOOK_TYPE = {
  OPEN: 'open',
  XID: 'xid'
};

export const ORDER_VIEW_TYPE = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};

export const TWOFA_STAGES = {
  INIT: 'init',
  VERIFY: 'verify',
  CONFIRM: 'confirm',
  APPROVE: 'approve',
  COMPLETED: 'completed'
};

export const SECURITY_LEVEL = {
  HIGH: 'high',
  MEDIUM: 'medium',
  NONE: 'none'
};

export const AUTHENTICATION_TYPES = {
  AUTHENTICATOR: 'Token::Authenticator',
  MOBILEOTP: 'Token::MobileOTP',
  NONE: 'Token::None'
};

export const CHANGE_2FA_SOURCES = {
  VERIFICATION: 'verification',
  SETTINGS: 'settings'
};
export const EMAIL_APPROVAL_ACTIONS = {
  CHANGE_2FA_TYPE: 'change_2fa_type',
  WITHDRAW: 'withdraw'
};

export const STATUS_TYPES = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success'
};

export const DYNAMIC_COMPONENT_TYPE = {
  TextField: 'TEXT_FIELD',
  DateTimeField: 'DATE_TIME_FIELD',
  TextArea: 'TEXT_AREA',
  DropDown: 'DROP_DOWN',
  NumberField: 'NUMBER_FIELD',
  FieldsGroup: 'FIELDS_GROUP',
  FileUpload: 'FILE_UPLOAD',
  DocumentCapture: 'DOCUMENT_CAPTURE',
  FaceCapture: 'FACE_CAPTURE'
};

export const DATA_SOURCE = {
  CONFIG: 'config',
  PROFILE: 'profile'
};

export const CURRENCY_TYPE = {
  FIAT: 'fiat',
  CRYPTO: 'crypto'
};

export const ORDER_TYPES = {
  LIMIT: 'limit',
  STOP_LIMIT: 'stop_limit',
  LIMIT_MAKER: 'limit_maker'
};

export const ORDER_TYPE_LABELS = {
  [ORDER_TYPES.LIMIT]: 'Limit',
  [ORDER_TYPES.STOP_LIMIT]: 'Stop Limit',
  [ORDER_TYPES.LIMIT_MAKER]: 'Limit Maker'
};

export const AVAILABLE_LANGUAGES = [
  { value: 'en-US', label: 'English (US)' }
  // { value: "id", label: "Indonesian" },
  // { value: "uk", label: "Ukrainian" },
  // { value: "zh", label: "Chinese (PRC)" }
];

export const ST_ORDER_SIDE = {
  MINT: 'mint',
  BURN: 'burn'
};

export const EXCHANGE_TABS = {
  0: 'markets',
  1: 'charts',
  2: 'buySell',
  3: 'trades',
  4: 'myOrders'
};

export const P2P_TABS = {
  HOW_IT_WORKS: 'how-it-works',
  ORDER_BOOK: 'orderbook',
  BUY_SELL: 'buy-sell',
  MATCH_HISTORY: 'match-history',
  ORDERS: 'orders'
};

export const VIEW_CONTEXT = {
  SPOT: 'spot',
  P2P: 'p2p'
};

export const TRADING_FILTER = {
  INR_MARKETS: 'inr_markets',
  OTHER_MARKETS: 'other_markets'
};

export const FEE_TABLE_TYPE = {
  DEPOSIT_AND_WITHDRAW: 'deposit_and_withdrawl',
  TRADING: 'trading'
};

export const LOGOUT_TYPE = {
  ALL: 0,
  CURRENT: 1
};

export const MY_ORDER_MODE = {
  COMPLETED: 'completed',
  OPEN: 'open'
};

export const THIRD_PARTY_SOURCES = {
  BINANCE: 'binance',
  WAZIRX: 'wazirx'
};

export const EVENT_SCOPE = {
  THIS: 'this',
  OTHERS: 'others',
  ALL: 'all'
};

export const PREFERENCE_CATEGORY_TYPE = {
  FEE: 'fee'
};

export const CAPTURE_TYPE = {
  DOCUMENT: 'DOCUMENT',
  FACE: 'FACE'
};

export const BANK_VERIFICATION_STATUS = {
  UNVERIFIED: 'unverified',
  VERIFYING: 'verifying',
  VERIFIED: 'verified',
  REJECTED: 'rejected',
  WAITING_FOR_KYC: 'waiting_for_kyc'
};

export const FAVOURITE = 'favourite';

export const TRADING_VIEW_RESOLUTIONS_MAPPER = {
  '1M': '1',
  '5M': '5',
  '15M': '15',
  '30M': '30',
  '1H': '60',
  '2H': '120',
  '4H': '240',
  '6H': '360',
  '12H': '720',
  '1D': '1D',
  '1W': '1W'
};

export const TRADING_VIEW_RESOLUTION_PERIOD_MAPPER = {
  '1': '1m',
  '5': '5m',
  '15': '15m',
  '30': '30m',
  '60': '1h',
  '120': '2h',
  '240': '4h',
  '360': '6h',
  '720': '12h',
  '1D': '1d',
  '1W': '1w'
};

export const TRADING_VIEW_RESOLUTION_PERIOD_TO_MINUTES_MAPPER = {
  '1': '1',
  '5': '5',
  '15': '15',
  '30': '30',
  '60': '60',
  '120': '120',
  '240': '240',
  '360': '360',
  '720': '720',
  '1D': '1440',
  '1W': '10080'
};

export const COUPON_STATE = {
  REWARDED: 'rewarded',
  PENDING: 'pending',
  EXPIRED: 'expired'
};

export const REWARD_CONSTRAINT = {
  INSTANT: 'INSTANT',
  DEPOSIT_WORTH_100_INR: 'DEPOSIT_WORTH_100_INR',
  TRADE_WORTH_5000_INR: 'TRADE_WORTH_5000_INR'
};

export const KYC_VERIFICATION_STATES = {
  UNVERIFIED: 'unverified',
  VERIFYING: 'verifying',
  AUTO_VERIFYING: 'auto_verifying',
  MANUALLY_VERIFYING: 'manually_verifying',
  PROCESSING_ERROR: 'processing_error',
  VERIFIED: 'verified',
  REJECTED: 'rejected',
  USER_INITIATED: 'user_initiated',
  UNDER_AGE: 'under_age',
  RESTART: 'restart',
  RESUBMIT: 'resubmit'
};

export const API_KEY_GENERATION_TYPE = {
  BASIC: 'basic',
  ADVANCED: 'advanced'
};

export const KYC_SUBMISSION_TYPE = {
  InstantKycVerification: 'InstantKycVerification',
  DefaultKycVerification: 'DefaultKycVerification'
};

export const DEPOSIT_RENDER_MODES = {
  STANDARD_BANK: 'standard-bank',
  INSTANT_BANK: 'instant-bank',
  INSTANT_GATEWAY_MOBIKWIK: 'instant-gateway_mobikwik',
  INSTANT_GATEWAY_NETBANKING: 'instant-gateway_netbanking',
  INSTANT_GATEWAY_CASHFREE: 'instant-gateway_cashfree',
  INSTANT_UPI_COLLECT: 'instant-upi_collect',
  INSTANT_UPI: 'instant-upi'
};

export const KYC_VERIFICATION_USER_STATE = {
  DONE: 'done',
  NOT_SUBMITTED: 'not_submitted',
  IN_REVIEW: 'in_review',
  REJECTED: 'rejected',
  VERIFYING: 'verifying',
  AUTO_VERIFICATION: 'auto_verifying',
  MANUALLY_VERIFYING: 'manually_verifying',
  PROCESSING_ERROR: 'processing_error',
  REVERIFICATION: 'reverification'
};

export const KYC_SOURCES = {
  P2P: 'p2p',
  INR_DEPOSIT: 'inr_deposit',
  INR_WITHDRAW: 'inr_withdraw',
  PAYMENT_OPTIONS: 'payment_options'
};

export const PAYMENT_OPTION_SOURCES = {
  P2P: 'p2p',
  INR_DEPOSIT: 'inr_deposit',
  INR_WITHDRAW: 'inr_withdraw',
  PAYMENT_OPTIONS: 'payment_options'
};

export const KYC_FEATURE_LIST = {
  DEPOSIT_INR: 'Deposit INR',
  DEPOSIT_CRYPTO: 'Deposit Crypto',
  WITHDRAW: 'Withdraw',
  TRADE: 'TRADE'
};

export const OPEN_IN_APP_POPUP_KEPT_HIDDEN_HOURS = 2;
export const OPEN_IN_APP_POPUP_ALLOWED_PAGES = [
  /^\/$/,
  /^\/exchange$/,
  /^\/exchange\/.*$/,
  /^\/p2p$/,
  /^\/p2p\/.*$/,
  /^\/stf$/,
  /^\/stf\/.*$/,
  /^\/invite\/.*$/,
  /^\/signup.*$/,
  /^\/verification\/kyc$/,
  /^\/settings\/payment$/,
  /^\/inr-deposit$/,
  /^\/inr-withdraw$/,
  /^\/quick-buy$/,
  /^\/quick-buy\/.*$/,
  /^\/gifts$/,
  /^\/gifts\/.*$/,
  /^\/gstin$/,
  /^\/funds$/,
  /^\/funds\/.*$/,
  /^\/deposit$/,
  /^\/deposit\/.*$/,
  /^\/withdraw$/,
  /^\/withdraw\/.*$/,
  /^\/reports$/,
  /^\/reports\/.*$/,
  /^\/settings\/reports$/,
  /^\/settings\/reports\/.*$/
];

export const HVSDKAdditionalErrorCodes = {
  '500': 'somethingWentWrong',
  '501': 'somethingWentWrong',
  '999': 'somethingWentWrong',
  '2': 'somethingWentWrong',
  '14': 'somethingWentWrong'
};

export const HVSDKDefaultErrorCodes = {
  '001': 'somethingWentWrong',
  '007': 'webcamNotFound',
  '010': 'noCameraPermission',
  '011': 'noDeviceIDs',
  '014': 'invalidDocument',
  '015': 'noCamera',
  '4': 'noCameraPermission',
  '5': 'noCamera',
  '11': 'somethingWentWrong',
  '12': 'networkError',
  '22': 'faceNotDetected',
  '23': 'faceNotClear'
};

export const P2P_UPLOAD_IMAGE_TYPES = [
  'application/pdf',
  'image/png',
  'image/jpeg'
];

export const MAX_TRADING_REPORT_DURATION_IN_MONTHS = 12;
export const TRADING_REPORT_PREFILLED_RANGES = {
  currentMonth: 'currentMonth',
  last30Days: 'last30Days',
  last60Days: 'last60Days',
  last90Days: 'last90Days',
  fy2021: 'fy2021',
  fy2122: 'fy2122',
  fy2223: 'fy2223',
  customDuration: 'customDuration'
};

export const MAX_GST_INVOICE_DURATION_IN_DAYS = 7;
export const GST_INVOICE_PREFILLED_RANGES = {
  yesterday: 'yesterday',
  currentWeek: 'currentWeek',
  last7Days: 'last7Days',
  customDuration: 'customDuration'
};

export const FINANCIAL_YEAR_20_21_START_DATE = '01-04-2020';
export const FINANCIAL_YEAR_20_21_END_DATE = '31-03-2021';
export const FINANCIAL_YEAR_21_22_START_DATE = '01-04-2021';
export const FINANCIAL_YEAR_21_22_END_DATE = '31-03-2022';
export const FINANCIAL_YEAR_22_23_START_DATE = '01-04-2022';
export const FINANCIAL_YEAR_22_23_END_DATE = '31-03-2023';

export const THEME = {
  DARK: 'dark',
  LIGHT: 'light'
};

export const MOBILE_NAVBAR_HEIGHT = 50;
export const MOBILE_BOTTOM_TABS_HEIGHT = 60;

export const PORTFOLIO_BALANCE_PECENTAGE_CHANGE_THRESHOLD = new Big(0.5);

export const COUNTRY_INDIA = 'IN';

export const GIFT_TABS = {
  RECEIVE: 'receive',
  SENT: 'sent'
};

export const GIFT_MODE = {
  RECEIVED: 'received',
  SENT: 'sent'
};

export const GIFT_STATUS = {
  INITIATED: 'INITIATED',
  SENT: 'SENT',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED'
};

export const P2P_PAYMENT_PROOF_EXAMPLE_URL =
  'https://wazirx.com/blog/which-payment-proofs-can-you-upload-on-wazirx-p2p/';

export const IS_BINANCE_DEACTIVATED = true;

export const IS_MONEYCONTROL_DEACTIVATED = true; // remove money control integration since contract is over

export const WAZIRX_ANDROID_APP_LINK =
  'https://play.google.com/store/apps/details?id=com.wrx.wazirx';
export const WAZIRX_IOS_APP_LINK =
  'itms-apps://itunes.apple.com/us/app/id/1349082789';
export const WAZIRX_IOS_APP_REVIEW_LINK = `${WAZIRX_IOS_APP_LINK}?action=write-review`;

export const FEATURE_FLAGS = {
  GSTIN_DISABLED: 'disabled'
};

export const REVISED_TRADING_FEES =
  'https://wazirx.com/blog/revised-trading-fees/';

export const REFERRAL_SUPPORT_PAGE = `https://wazirx.com/blog/new-wazirx-referral-program/`;

export const NEW_MARKET_MESSAGE =
  'This is a newly listed market and subject to high price volatility. Make sure to undertake sufficient risk assessment when trading newly listed tokens. WazirX will not be responsible for your trading losses.';
export const NEW_MARKET_TITLE = 'Risk Warning';
