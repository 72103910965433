import { fromJS } from 'immutable';
import * as types from '../constants/ActionTypes';

import {
  normalizeConfig,
  normalizeMarketStatus
} from '../utils/normalizer/common';
import { setJSONObjectToLocalStorage } from '../utils/localStorageHelpers';

const initialState = fromJS({
  config: {
    isFetching: false,
    isFetchingError: false,
    isFetchedOnce: false
  },
  isPageVisible: true,
  socketStates: fromJS({ previous: 'unavailable', current: 'unavailable' }),
  marketStatus: {
    isFetching: false,
    isFetchedError: false
  },
  confirmEmail: {
    isSending: false,
    isSendingError: false
  },
  sendOTP: {
    isSendingOTP: false,
    isSendingOTPError: false,
    data: null,
    message: null
  },
  resendOTP: {
    isResendingOTP: false,
    isResendingOTPError: false,
    data: null,
    message: null
  },
  featureFlags: {
    isFetching: false,
    isFetchingError: false,
    isFetchedOnce: false,
    data: null
  }
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_CONFIG_INIT:
      return state.mergeIn(['config'], {
        isFetching: true,
        isFetchingError: false
      });

    case types.FETCH_CONFIG_SUCCESS: {
      const normalizedConfig = normalizeConfig(action.data);
      setJSONObjectToLocalStorage('config', normalizedConfig);
      return state.mergeIn(['config'], {
        isFetching: false,
        isFetchingError: false,
        isFetchedOnce: true,
        data: normalizedConfig
      });
    }
    case types.FETCH_CONFIG_ERROR:
      return state.mergeIn(['config'], {
        isFetching: false,
        isFetchingError: true,
        error: action.error
      });
    case types.SET_IS_PAGE_VISIBLE:
      return state.set('isPageVisible', action.isPageVisible);
    case types.SET_SOCKET_STATES:
      return state.set('socketStates', fromJS(action.socketStates));

    case types.FETCH_MARKET_STATUS_INIT:
      return state.mergeIn(['marketStatus'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_MARKET_STATUS_SUCCESS:
      return state.mergeIn(['marketStatus'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeMarketStatus(action.data)
      });
    case types.FETCH_MARKET_STATUS_ERROR:
      return state.mergeIn(['marketStatus'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.CONFIRM_EMAIL_RESET:
      return state.mergeIn(['confirmEmail'], {
        isSending: false,
        isSendingError: false,
        message: null,
        data: null
      });
    case types.CONFIRM_EMAIL_INIT:
      return state.mergeIn(['confirmEmail'], {
        isSending: true,
        isSendingError: false
      });
    case types.CONFIRM_EMAIL_SUCCESS:
      return state.mergeIn(['confirmEmail'], {
        isSending: false,
        isSendingError: false,
        data: action.data
      });
    case types.CONFIRM_EMAIL_ERROR:
      return state.mergeIn(['confirmEmail'], {
        isSending: false,
        isSendingError: true,
        message: action.message
      });
    case types.RESET_SEND_OTP:
      return state.mergeIn(['sendOTP'], {
        isSendingOTP: false,
        isSendingOTPError: false,
        message: null
      });
    case types.SEND_OTP_INIT:
      return state.mergeIn(['sendOTP'], {
        isSendingOTP: true,
        isSendingOTPError: false,
        data: null
      });

    case types.SEND_OTP_SUCCESS:
      return state.mergeIn(['sendOTP'], {
        isSendingOTP: false,
        isSendingOTPError: false,
        data: action.data
      });
    case types.SEND_OTP_ERROR:
      return state.mergeIn(['sendOTP'], {
        isSendingOTP: false,
        isSendingOTPError: true,
        message: action.message
      });

    case types.RESEND_OTP_INIT:
      return state.mergeIn(['resendOTP'], {
        isResendingOTP: true,
        isResendingOTPError: false,
        data: null
      });
    case types.RESEND_OTP_SUCCESS:
      return state.mergeIn(['resendOTP'], {
        isResendingOTP: false,
        isResendingOTPError: false,
        data: action.data
      });

    case types.RESEND_OTP_ERROR:
      return state.mergeIn(['resendOTP'], {
        isResendingOTP: false,
        isResendingOTPError: true,
        message: action.message
      });

    case types.RESET_RESEND_OTP: {
      return state.mergeIn(['resendOTP'], {
        isResendingOTP: false,
        isResendingOTPError: false,
        message: null
      });
    }

    case types.FEATURE_FLAGS_INIT:
      return state.mergeIn(['featureFlags'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FEATURE_FLAGS_SUCCESS: {
      return state.mergeIn(['featureFlags'], {
        isFetching: false,
        isFetchingError: false,
        isFetchedOnce: true,
        data: action.data
      });
    }
    case types.FEATURE_FLAGS_ERROR:
      return state.mergeIn(['featureFlags'], {
        isFetching: false,
        isFetchingError: true,
        error: action.error
      });

    default:
      return state;
  }
}
